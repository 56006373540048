import React, { Component } from 'react';
import Axios from 'axios';

export default class extends Component {

    state = {
        emailClass: 'form-control',
        subjectClass: 'form-control',
        detailsClass: 'form-control',
        status: 'Submit',
        statusClass: 'btn btn-info'
    }

    email = React.createRef()
    subject = React.createRef()
    details = React.createRef()

    submit = (e) => {

        const email = this.email.current.value;
        const emailClass = 'form-control' + (email ? '' : ' error');
        const subject = this.subject.current.value;

        const subjectClass = 'form-control' + (subject ? '' : ' error');
        const details = this.details.current.value;
        const detailsClass = 'form-control' + (details ? '' : ' error');

        const complete = email && subject && detailsClass;

        this.setState({ emailClass, subjectClass, detailsClass }, async () => {

            if (complete) {
                let statusClass = 'btn btn-info';
                try {
                    const { data } = await Axios.post('/api/contactrequest', {
                        email, subject, details
                    });    
                    if (data.success) {
                        statusClass = 'btn btn-success';
                        this.setState({ status: 'Submitted', statusClass });
                        setTimeout(function(){
                            location.href="/"; 
                        }, 1500)
                    }
                    else {
                        statusClass = 'btn btn-danger';
                        this.setState({ status: 'Error', statusClass });
                    }    
                }
                catch (e) {
                    statusClass = 'btn btn-danger';
                    this.setState({ status: 'Error', statusClass });
                }
    
            }
        });
    }
    render() {

        const { t } = this.props;

        return (
            <>
                <div className="container contact-form">
                    <div className="row justify-content-center mt-3 mb-3">
                        <div className="col-9">
                            <h1>{t('Drop Request')}</h1>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3 mb-3">
                        <div className="container card">
                            <div className="row justify-content-center mt-3 mb-3">
                                <div className="col-11 col-lg-8">
                                    <label>{t('Email')}</label>
                                    <input ref={this.email} className={this.state.emailClass} type="email" placeholder="name@example.com" />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-3 mb-3">
                                <div className="col-11 col-lg-8">
                                    <label>{t('Subject')}</label>
                                    <input ref={this.subject} className={this.state.subjectClass} type="text" placeholder={t('need help for ...')} />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-3 mb-3">
                                <div className="col-11 col-lg-8">
                                    <label>{t('Details')}</label>
                                    <textarea ref={this.details} rows="6" className={this.state.detailsClass} />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-3 mb-3">
                                <div className="col-9 d-flex justify-content-center">
                                    <input type="submit" className={this.state.statusClass} value={t(this.state.status)} onClick={this.submit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
